.woocommerce {
	&.woocommerce-account {
		.woocommerce-EditAccountForm,
		.woocommerce-address-fields {
			button.button {
				background-color: $brand-orange !important;
				&:hover {
					background-color: darken($brand-orange, 5%) !important;
				}
			}
		}

		.woocommerce-MyAccount-navigation {
			margin-bottom: 1.5rem;
			padding: 1.25rem;
			border: solid 1px $form-input-border-color;
			background-color: rgba(255, 255, 255, 0.25);

			ul {
				margin: 0;
				li {
					a {
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}

			}

		}
	}
}


@media(min-width: 992px) {
	.woocommerce {
		&.woocommerce-account {
			.woocommerce-MyAccount-navigation {
				width: 25%;
			}
		}
	}
}
