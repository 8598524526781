/* ~~~~~~~~~~~~~~~~ CHECKOUT ~~~~~~~~~~~~~~~~ */

$payment-bg: #ebf0f3;
$payment-box-bg: #d2dce1;

.woocommerce {
	&.checkout {
		#billing_phone_field {
			display: none;
		}

		#billing_iban_number_field {
			margin: 2rem 0;
			padding: 2rem 1rem;
			border: solid 1px $form-input-border-color;
		}

		.woocommerce-billing-fields h3 {
			font-family: "Noto Sans", Helvetica, Arial, sans-serif;
			margin-top: 4rem;
			padding: 0.75rem;
			background-color: $brand-blue;
			color: $pure-white;
		}

		#order_review_heading {
			font-family: "Noto Sans", Helvetica, Arial, sans-serif;
			margin-top: 4rem;
			padding: 0.75rem;
			background-color: $brand-blue;
			color: $pure-white;
		}
	}
}


.woocommerce-cart #payment,
.woocommerce-checkout #payment,
#add_payment_method #payment {
	background-color: $payment-bg;
}

.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box,
#add_payment_method #payment div.payment_box {
	background-color: $payment-box-bg;
}

.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before,
#add_payment_method #payment div.payment_box::before {
	border-bottom-color: $payment-box-bg;
}

.woocommerce-checkout-review-order-table {
	background-color: $pure-white;
}

.woocommerce #payment #place_order.button.button.alt,
.woocommerce-page #payment #place_order.button.button.alt {
	border-radius: 0 !important;
	background-color: $brand-orange !important;
	color: $pure-white;
	&:hover {
		background-color: darken($brand-orange, 5%) !important;
	}
}

@media(max-width:768px) {
	.woocommerce  {
		.woocommerce-message  {
			.button {
				padding: 3px 10px !important;
				font-size: 14px !important;
			}
		}
	}
}

@media (min-width: 992px) {

	.woocommerce {
		&.checkout {

			form {
				.form-row {
					clear: both;

					label {
						display: inline-block;
						width: 33%;
						margin: 0 !important;
					}
				}

				.form-row-first {
					float: none !important;
					width: 100%;
				}

				.form-row-last {
					float: none !important;
					width: 100%;
				}

				.password-input {
					/* override flex */
					display: inline-block;
				}
			}

			.woocommerce-input-wrapper {
				display: inline-block;
				width: 66%;
				margin: 0 !important;
			}

			#billing_iban_number_field label {
				display: block !important;
				width: 100%;
			}
			#billing_iban_number_field .woocommerce-input-wrapper {
				display: block !important;
				width: 100%;
			}

		}
	}
}
