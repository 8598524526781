/* ~~~~~~~~~~~~~~~~~~ Login ~~~~~~~~~~~~~~~~~~ */

.forgot {
	.woocommerce {
		h2 {
			font-size: 1.75rem;
			text-align: center;
			color: $brand-orange;
		}

		form {
			.form-row {
				label {
					width: 100% !important;
				}
				input.input-text {
					padding: 0.375rem 1.5rem;
					border: solid 1px $form-input-border-color;
				}
			}
			.form-row-first {
				width: 100% !important;
			}

		}

	}

}

