.woocommerce {
	&.shop {
		ul.products {
			li.product {
				margin: 1rem 1%;
				padding: 1rem;
				width: 30% !important;
				text-align: center;
				min-height: 370px;
				background-color: rgba(255,255,255,0.75);
				box-shadow: 0 0 4px 1px rgb(0 0 0 / 0.12);

				a img {
					margin-bottom: 0.75rem;
					box-shadow: 0 0 2px 0 rgb(0 0 0 / 0.15);
				}

				.woocommerce-loop-product__title,
				h3 {
					height: 60px;
					overflow-y: hidden;
				}
			}
		}

	}

}

@media(max-width:768px) {
	.woocommerce {
		&.shop {
			ul.products {
				li.product {
					margin: 1rem 1%;
					width: 48% !important;
				}
			}
		}
	}
}
