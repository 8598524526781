.woocommerce {
	&.shopsingle {
		.woocommerce-product-gallery__image {
			box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);

		}

		div.product p.price,
		.woocommerce div.product span.price {
			color: $brand-orange;
			font-size: 1.75rem;
		}

		.quantity .qty {
			width: 5rem;
			padding: 5px 10px;
			border: solid 1px $form-input-border-color;

		}

		button.button.single_add_to_cart_button {
			padding: 0.375rem 3rem !important;
			background-color: $brand-orange !important;
			border-radius: 0 !important;
		}



		div.product .woocommerce-tabs ul.tabs {

			margin-bottom: 3rem;

			&::before {
				border-bottom-color: $form-input-border-color;
			}

			li {
				border-top-color: $form-input-border-color;
				border-left-color: $form-input-border-color;
				border-right-color: $form-input-border-color;
				border-radius: 0;

				&::before {
					border-color: $form-input-border-color;
					border-bottom-right-radius: 0 !important;
				}

				&::after {
					border-color: $form-input-border-color;
					border-bottom-left-radius: 0 !important;
				}

				a {
					font-weight: 400 !important;
				}
			}

		}

		.panel {
			h2 {
				display: none;
			}
		}

		.up-sells {

			position: relative;
			z-index: 2;

			// force div to full width;
			width: calc(100vw);
			margin-left: calc(-50vw + 50%);

			padding: 2.5rem;
			box-shadow: 0 0 1.25rem rgb(0 0 0 / 15%);
			background-color: $sand-bg;

			&:before {

				content: "";
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: -1;
				left: 0;
				top: 0;
				pointer-events: none;

				opacity: 0.7;
				background-image: url(../img/noise.png);
				background-repeat: repeat;
			}

			h2 {
				max-width: 1108px; // container fluid: 1140 - 2 * 16
				margin-left: auto;
				margin-right: auto;

				font-size: 1.25rem;
				font-weight: 400;
				color: $brand-blue;
				margin-bottom: 2rem;
			}

			ul.products {
				max-width: 1108px; // container fluid: 1140 - 2 * 16
				margin-left: auto;
				margin-right: auto;
				li.product {
					text-align: center;
					a img {
						box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
					}
				}
			}

		}

	}
}

@media(min-width: 768px) {
	.woocommerce.shopsingle div.product div.images {
		width: 42%;
		margin-right: 6%;
	}
}
