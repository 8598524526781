$woo-message-border: #eee;
$woo-message-error-border: #b81c23;

.woocommerce {
	margin: 0;

	form {
		.form-row {
			input.input-text,
			textarea,
			select {
				color: $body-text-color;
				padding: 0.375rem 1.5rem;
				border: solid 1px $form-input-border-color;
				border-radius: 0;
			}
		}
	}

	.woocommerce-message,
	.woocommerce-error,
	.woocommerce-info {
		background-color: $pure-white;
		border-left: solid 1px $woo-message-border;
		border-right: solid 1px $woo-message-border;
		border-bottom: solid 1px $woo-message-border;
	}

	.woocommerce-message {
		border-top-color: $brand-orange;
	}

	.woocommerce-info {
		border-top-color: $brand-blue;
	}

	.woocommerce-error {
		border-top-color: $woo-message-error-border;
	}

	a.button,
	button.button {
		background-color: $brand-blue !important;
		color: $pure-white;
		font-size: 1rem;
		font-weight: normal;
		border-radius: 0 !important;
		&:hover {
			background-color: $brand-dark-blue !important;
			color: $pure-white;
		}
	}
}

