/* ~~~~~~~~~~~~~~~~~~ CART ~~~~~~~~~~~~~~~~~~ */

.woocommerce {

	&.cart {
		table.shop_table {
			background-color: #fff;
			border-radius: 0;
		}

		.wc-proceed-to-checkout a.button {
			background-color: $brand-orange !important;
			border-radius: 0 !important;
			&:hover {
				background-color: darken($brand-orange, 5%) !important;
			}
		}
		table.cart {
			img {
				width: 60px;
				height: auto;
				box-shadow: 0 0 3px 0 rgba(0,0,0,0.25);
			}

			td.actions  {
				.input-text {
					height: 2.25rem;
					padding: 0.25rem 0.5rem;
					border: solid 1px $form-input-border-color;
				}
				.coupon .button {
					margin-top: 0 !important;
				}
			}
		}
		.quantity .qty {
			border: solid 1px $form-input-border-color;
		}
	}

}

