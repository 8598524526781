/* ~~~~~~~~~~~~~~~~~~ Login ~~~~~~~~~~~~~~~~~~ */

.login {
	.woocommerce {
		h2 {
			font-size: 1.75rem;
			text-align: center;
			color: #F37347;
		}

		form {
			&.login {
				border: none;
			}
		}
	}
}

