/* Fonts */
/* Colors */
.woocommerce {
  margin: 0;
}
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea,
.woocommerce form .form-row select {
  color: #3d3d3d;
  padding: 0.375rem 1.5rem;
  border: solid 1px rgb(206, 212, 218);
  border-radius: 0;
}
.woocommerce .woocommerce-message,
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info {
  background-color: #fff;
  border-left: solid 1px #eee;
  border-right: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
.woocommerce .woocommerce-message {
  border-top-color: rgb(243, 115, 71);
}
.woocommerce .woocommerce-info {
  border-top-color: rgb(85, 130, 150);
}
.woocommerce .woocommerce-error {
  border-top-color: #b81c23;
}
.woocommerce a.button,
.woocommerce button.button {
  background-color: rgb(85, 130, 150) !important;
  color: #fff;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 0 !important;
}
.woocommerce a.button:hover,
.woocommerce button.button:hover {
  background-color: rgb(51, 87, 97) !important;
  color: #fff;
}

.woocommerce.shop ul.products li.product {
  margin: 1rem 1%;
  padding: 1rem;
  width: 30% !important;
  text-align: center;
  min-height: 370px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
}
.woocommerce.shop ul.products li.product a img {
  margin-bottom: 0.75rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
}
.woocommerce.shop ul.products li.product .woocommerce-loop-product__title,
.woocommerce.shop ul.products li.product h3 {
  height: 60px;
  overflow-y: hidden;
}

@media (max-width: 768px) {
  .woocommerce.shop ul.products li.product {
    margin: 1rem 1%;
    width: 48% !important;
  }
}
.woocommerce.shopsingle .woocommerce-product-gallery__image {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
}
.woocommerce.shopsingle div.product p.price,
.woocommerce.shopsingle .woocommerce div.product span.price {
  color: rgb(243, 115, 71);
  font-size: 1.75rem;
}
.woocommerce.shopsingle .quantity .qty {
  width: 5rem;
  padding: 5px 10px;
  border: solid 1px rgb(206, 212, 218);
}
.woocommerce.shopsingle button.button.single_add_to_cart_button {
  padding: 0.375rem 3rem !important;
  background-color: rgb(243, 115, 71) !important;
  border-radius: 0 !important;
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs {
  margin-bottom: 3rem;
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs::before {
  border-bottom-color: rgb(206, 212, 218);
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs li {
  border-top-color: rgb(206, 212, 218);
  border-left-color: rgb(206, 212, 218);
  border-right-color: rgb(206, 212, 218);
  border-radius: 0;
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs li::before {
  border-color: rgb(206, 212, 218);
  border-bottom-right-radius: 0 !important;
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs li::after {
  border-color: rgb(206, 212, 218);
  border-bottom-left-radius: 0 !important;
}
.woocommerce.shopsingle div.product .woocommerce-tabs ul.tabs li a {
  font-weight: 400 !important;
}
.woocommerce.shopsingle .panel h2 {
  display: none;
}
.woocommerce.shopsingle .up-sells {
  position: relative;
  z-index: 2;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding: 2.5rem;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
  background-color: rgb(253, 243, 238);
}
.woocommerce.shopsingle .up-sells:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0.7;
  background-image: url(../img/noise.png);
  background-repeat: repeat;
}
.woocommerce.shopsingle .up-sells h2 {
  max-width: 1108px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  font-weight: 400;
  color: rgb(85, 130, 150);
  margin-bottom: 2rem;
}
.woocommerce.shopsingle .up-sells ul.products {
  max-width: 1108px;
  margin-left: auto;
  margin-right: auto;
}
.woocommerce.shopsingle .up-sells ul.products li.product {
  text-align: center;
}
.woocommerce.shopsingle .up-sells ul.products li.product a img {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
}

@media (min-width: 768px) {
  .woocommerce.shopsingle div.product div.images {
    width: 42%;
    margin-right: 6%;
  }
}
/* ~~~~~~~~~~~~~~~~~~ Login ~~~~~~~~~~~~~~~~~~ */
.login .woocommerce h2 {
  font-size: 1.75rem;
  text-align: center;
  color: #F37347;
}
.login .woocommerce form.login {
  border: none;
}

/* ~~~~~~~~~~~~~~~~~~ Login ~~~~~~~~~~~~~~~~~~ */
.forgot .woocommerce h2 {
  font-size: 1.75rem;
  text-align: center;
  color: rgb(243, 115, 71);
}
.forgot .woocommerce form .form-row label {
  width: 100% !important;
}
.forgot .woocommerce form .form-row input.input-text {
  padding: 0.375rem 1.5rem;
  border: solid 1px rgb(206, 212, 218);
}
.forgot .woocommerce form .form-row-first {
  width: 100% !important;
}

/* ~~~~~~~~~~~~~~~~~~ CART ~~~~~~~~~~~~~~~~~~ */
.woocommerce.cart table.shop_table {
  background-color: #fff;
  border-radius: 0;
}
.woocommerce.cart .wc-proceed-to-checkout a.button {
  background-color: rgb(243, 115, 71) !important;
  border-radius: 0 !important;
}
.woocommerce.cart .wc-proceed-to-checkout a.button:hover {
  background-color: #f1612f !important;
}
.woocommerce.cart table.cart img {
  width: 60px;
  height: auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
}
.woocommerce.cart table.cart td.actions .input-text {
  height: 2.25rem;
  padding: 0.25rem 0.5rem;
  border: solid 1px rgb(206, 212, 218);
}
.woocommerce.cart table.cart td.actions .coupon .button {
  margin-top: 0 !important;
}
.woocommerce.cart .quantity .qty {
  border: solid 1px rgb(206, 212, 218);
}

/* ~~~~~~~~~~~~~~~~ CHECKOUT ~~~~~~~~~~~~~~~~ */
.woocommerce.checkout #billing_phone_field {
  display: none;
}
.woocommerce.checkout #billing_iban_number_field {
  margin: 2rem 0;
  padding: 2rem 1rem;
  border: solid 1px rgb(206, 212, 218);
}
.woocommerce.checkout .woocommerce-billing-fields h3 {
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  margin-top: 4rem;
  padding: 0.75rem;
  background-color: rgb(85, 130, 150);
  color: #fff;
}
.woocommerce.checkout #order_review_heading {
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  margin-top: 4rem;
  padding: 0.75rem;
  background-color: rgb(85, 130, 150);
  color: #fff;
}

.woocommerce-cart #payment,
.woocommerce-checkout #payment,
#add_payment_method #payment {
  background-color: #ebf0f3;
}

.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box,
#add_payment_method #payment div.payment_box {
  background-color: #d2dce1;
}

.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before,
#add_payment_method #payment div.payment_box::before {
  border-bottom-color: #d2dce1;
}

.woocommerce-checkout-review-order-table {
  background-color: #fff;
}

.woocommerce #payment #place_order.button.button.alt,
.woocommerce-page #payment #place_order.button.button.alt {
  border-radius: 0 !important;
  background-color: rgb(243, 115, 71) !important;
  color: #fff;
}
.woocommerce #payment #place_order.button.button.alt:hover,
.woocommerce-page #payment #place_order.button.button.alt:hover {
  background-color: #f1612f !important;
}

@media (max-width: 768px) {
  .woocommerce .woocommerce-message .button {
    padding: 3px 10px !important;
    font-size: 14px !important;
  }
}
@media (min-width: 992px) {
  .woocommerce.checkout form .form-row {
    clear: both;
  }
  .woocommerce.checkout form .form-row label {
    display: inline-block;
    width: 33%;
    margin: 0 !important;
  }
  .woocommerce.checkout form .form-row-first {
    float: none !important;
    width: 100%;
  }
  .woocommerce.checkout form .form-row-last {
    float: none !important;
    width: 100%;
  }
  .woocommerce.checkout form .password-input {
    /* override flex */
    display: inline-block;
  }
  .woocommerce.checkout .woocommerce-input-wrapper {
    display: inline-block;
    width: 66%;
    margin: 0 !important;
  }
  .woocommerce.checkout #billing_iban_number_field label {
    display: block !important;
    width: 100%;
  }
  .woocommerce.checkout #billing_iban_number_field .woocommerce-input-wrapper {
    display: block !important;
    width: 100%;
  }
}
.woocommerce.woocommerce-account .woocommerce-EditAccountForm button.button,
.woocommerce.woocommerce-account .woocommerce-address-fields button.button {
  background-color: rgb(243, 115, 71) !important;
}
.woocommerce.woocommerce-account .woocommerce-EditAccountForm button.button:hover,
.woocommerce.woocommerce-account .woocommerce-address-fields button.button:hover {
  background-color: #f1612f !important;
}
.woocommerce.woocommerce-account .woocommerce-MyAccount-navigation {
  margin-bottom: 1.5rem;
  padding: 1.25rem;
  border: solid 1px rgb(206, 212, 218);
  background-color: rgba(255, 255, 255, 0.25);
}
.woocommerce.woocommerce-account .woocommerce-MyAccount-navigation ul {
  margin: 0;
}
.woocommerce.woocommerce-account .woocommerce-MyAccount-navigation ul li a {
  text-decoration: none;
}
.woocommerce.woocommerce-account .woocommerce-MyAccount-navigation ul li a:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .woocommerce.woocommerce-account .woocommerce-MyAccount-navigation {
    width: 25%;
  }
}